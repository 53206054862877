import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "components/Container";
import Box from "@mui/material/Box";
import { Content, SidebarArticles, Ad, ErrorPage } from "./components";
import { SearchResult } from "components/SearchResult";
import { SearchBox } from "components/SearchBox";
import { PostCardList } from "components/PostCardList";
import { SubscriptionCard } from "components/SubscriptionCard";
import { SEO } from "components/SEO";
import { useTranslation, I18nextContext } from "gatsby-plugin-react-i18next";
import searchBlogs from "utils/functions/searchBlogs";
import { HOSTNAME_LIVE } from "utils/constants";
import { useStaticQuery, graphql } from "gatsby";
import { set } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
  },
  sidebarNewsletter: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
    },
  },
  footerNewsletterSection: {
    background: theme.palette.primary.dark,
  },
}));

const BlogArticle = ({ singleBlogData, ad }): JSX.Element => {
  const context = React.useContext(I18nextContext);
  const i18Context = React.useContext(I18nextContext);
  const lang = i18Context.language;
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query categoryQueryAndMyQuery1 {
      DJANGO {
        allPost(eBlogStatus: "Published", orderBy: ["from_position"]) {
          edges {
            node {
              highlightsCheck
              createdDate
              engBanner
              chnBanner
              engThumbnail
              chnThumbnail
              engContent
              chnContent
              engCategories {
                catNameEng
                catNameChn
              }
              engTags {
                tagNameEng
                tagNameChn
              }
              engSlug
              engTitle
              chnTitle
              engCaption
              chnCaption
              engKeywords
              chnKeywords
            }
          }
        }
      }
    }
  `);

  const [searchValue, setSearchValue] = useState("");
  const [seacrhFilterRsult, setSearchFilterResult] = useState([]);
  const [searchView, setSearchView] = useState(false);
  const [blogData, setBlogData] = useState([]);
  const [add, setAdd] = useState();
  const { t } = useTranslation();
  const [allBlogs, setAllBlogs] = useState([]);
  const [highlightedData, setHighlightedData] = useState([]);
  const [blogTitles, setBlogTitles] = useState([]);
  const [featuredBlogs, setFeaturedBlogs] = useState([]);
  const [popularBlogs, setPopularBlogs] = useState([]);

  useEffect(() => {
    let titles = data.DJANGO?.allPost?.edges.map((item) => {
      return {
        engLabel: item.node.engTitle,
        chnLabel: item.node.chnTitle ? item.node.chnTitle : item.node.engTitle,
      };
    });
    setAllBlogs(
      data.DJANGO?.allPost?.edges.map((post) => {
        if (!post.node.chnBanner) {
          post.node.chnBanner = post.node.engBanner;
        }
        if (!post.node.chnThumbnail) {
          post.node.chnThumbnail = post.node.engThumbnail;
        }
        if (!post.node.chnContent) {
          post.node.chnContent = post.node.engContent;
        }
        if (!post.node.chnTitle) {
          post.node.chnTitle = post.node.engTitle;
        }
        if (!post.node.chnCaption) {
          post.node.chnCaption = post.node.engCaption;
        }
        if (!post.node.chnKeywords) {
          post.node.chnKeywords = post.node.engKeywords;
        }
        return post;
      })
    );
    setFeaturedBlogs(
      data.DJANGO?.allPost?.edges
        .filter((item: any) => item.node.engTitle !== singleBlogData.engTitle)
        .slice(-3)
        .map((post) => {
          if (!post.node.chnBanner) {
            post.node.chnBanner = post.node.engBanner;
          }
          if (!post.node.chnThumbnail) {
            post.node.chnThumbnail = post.node.engThumbnail;
          }
          if (!post.node.chnContent) {
            post.node.chnContent = post.node.engContent;
          }
          if (!post.node.chnTitle) {
            post.node.chnTitle = post.node.engTitle;
          }
          if (!post.node.chnCaption) {
            post.node.chnCaption = post.node.engCaption;
          }
          if (!post.node.chnKeywords) {
            post.node.chnKeywords = post.node.engKeywords;
          }
          return post;
        })
    );
    setPopularBlogs(
      data.DJANGO?.allPost?.edges
        .filter(
          (item: any) =>
            item.node.engTitle ===
            "Sharing Office 101 - A Guide to Choosing the Perfect Workspace"
        )
        .map((post) => {
          if (!post.node.chnBanner) {
            post.node.chnBanner = post.node.engBanner;
          }
          if (!post.node.chnThumbnail) {
            post.node.chnThumbnail = post.node.engThumbnail;
          }
          if (!post.node.chnContent) {
            post.node.chnContent = post.node.engContent;
          }
          if (!post.node.chnTitle) {
            post.node.chnTitle = post.node.engTitle;
          }
          if (!post.node.chnCaption) {
            post.node.chnCaption = post.node.engCaption;
          }
          if (!post.node.chnKeywords) {
            post.node.chnKeywords = post.node.engKeywords;
          }
          return post;
        })
    );
    let filterHighlighted = data.DJANGO?.allPost?.edges.filter(
      (item: any) => item.node.highlightsCheck === true && item.node.chnContent
    );
    setHighlightedData(filterHighlighted);
    setBlogTitles(titles);
  }, [data, lang]);

  useEffect(() => {
    setAdd(ad[0]);
    if (lang !== "en" && allBlogs) {
      let tempBlogs = allBlogs.filter((i) => {
        if (i.node.chnContent) {
          return true;
        } else {
          return false;
        }
      });
      setBlogData(tempBlogs);
    } else {
      setBlogData(allBlogs);
    }
  }, [lang, allBlogs, ad]);

  const searchFilterFunction = (value: string) => {
    let filterResult = searchBlogs(blogData, value, lang);
    setSearchValue(value);
    setSearchFilterResult(filterResult);
    setSearchView(true);
  };

  const onBackBlog = () => {
    setSearchView(false);
  };

  const [keywords, setKeywords] = useState([]);

  const fetchKeywords = async () => {
    try {
      const res = await fetch(
        `${HOSTNAME_LIVE}/api/search/get_tag_for_search/`
      );
      if (res.status === 200) {
        const json = await res.json();
        setKeywords(json.data);
      } else {
        console.log("error fetching search tags");
      }
    } catch (error) {
      console.log("error fetching search tags", error);
    }
  };

  useEffect(() => {
    fetchKeywords();
  }, []);

  const [SEOkeywords, setSEOKeywords] = useState("");

  useEffect(() => {
    let tempKeywords = [];
    if (lang === "en") {
      tempKeywords = singleBlogData?.engTags?.map((i) => i.keywordsEng);
    } else {
      tempKeywords = singleBlogData?.engTags?.map((i) => i.keywordsChn);
    }
    let stringTempKeywords = String(tempKeywords).replace(",", ", ");
    setSEOKeywords(
      `${
        lang === "en"
          ? singleBlogData?.engKeywords
          : singleBlogData?.chnKeywords
      }, ${stringTempKeywords}`
    );
  }, [singleBlogData, lang]);

  console.log(singleBlogData, "popularBlogs");

  return (
    <>
      <SEO
        title={
          context.language === "en"
            ? singleBlogData?.engTitle
            : singleBlogData?.chnTitle
        }
        description={
          context.language === "en"
            ? singleBlogData?.engCaption
            : singleBlogData?.chnCaption
        }
        keywords={SEOkeywords}
        image={
          context.language === "en"
            ? singleBlogData?.engThumbnail
            : singleBlogData?.chnThumbnail
        }
        lang={context.language}
      />
      <div className={classes.root}>
        {searchView === false ? (
          <Container
            paddingY={0}
            paddingTop={{ xs: "24px", sm: "32px", md: "48px" }}
          >
            <Container
              sx={{
                zIndex: 3,
                paddingY: "0 !important",
              }}
            >
              <SearchBox
                onSearch={searchFilterFunction}
                highlightedData={highlightedData}
                blogTitles={blogTitles}
                keywords={keywords}
              />
            </Container>
            <Box
              sx={{
                backgroundImage: `url(${
                  lang === "en"
                    ? singleBlogData?.engBanner
                    : singleBlogData?.chnBanner
                })`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "10px",
                marginTop: { xs: "16px", sm: "24px", md: "32px" },
                paddingTop: 0,
              }}
              minHeight={{ xs: "250px", sm: "300px", md: "350px" }}
              maxHeight={"350px"}
            ></Box>
          </Container>
        ) : (
          <Container
            sx={{
              marginTop: { xs: "24px", sm: "32px" },
              position: "relative",
              zIndex: 3,
              paddingY: "0 !important",
            }}
          >
            <SearchBox
              onSearch={searchFilterFunction}
              highlightedData={highlightedData}
              blogTitles={blogTitles}
              keywords={keywords}
            />
          </Container>
        )}

        {searchView === false ? (
          <>
            <Container paddingY={{ xs: 2, sm: 4, md: 6 }}>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: {
                      xs: "column",
                      sm: "column",
                      md: "column",
                      lg: "row",
                    },
                    gap: "48px",
                  }}
                >
                  <Box
                    sx={{
                      flex: 1,
                      position: "relative",
                    }}
                  >
                    {lang !== "en" ? (
                      singleBlogData?.cBlogStatus === "HIDDEN" ||
                      singleBlogData?.cBlogStatus === "DRAFT" ? (
                        <>
                          <ErrorPage />

                          <Content data={singleBlogData} />
                        </>
                      ) : (
                        <Box>
                          <Content data={singleBlogData} />
                        </Box>
                      )
                    ) : (
                      <Box>
                        <Content data={singleBlogData} />
                      </Box>
                    )}
                  </Box>
                  <Box
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "100%",
                        md: "100%",
                        lg: "340px",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: {
                          xs: "column",
                          sm: "column",
                          md: "row",
                          lg: "column",
                        },
                        gap: "48px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: {
                            xs: "column",
                            sm: "row",
                            md: "row",
                            lg: "column",
                          },
                          gap: {
                            xs: "48px",
                            sm: "32px",
                            md: "32px",
                            lg: "48px",
                          },
                        }}
                      >
                        <SidebarArticles highlightData={popularBlogs} />
                        <Ad ad={add} />
                      </Box>
                      <Box display={{ xs: "none", sm: "none", md: "block" }}>
                        <SubscriptionCard type="singleBlog" />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <div style={{ marginTop: "48px" }}>
                  <PostCardList
                    highlightedData={featuredBlogs}
                    title={t("lookingForThese")}
                  />
                </div>
                <Box
                  display={{ xs: "block", sm: "block", md: "none" }}
                  marginTop="48px"
                >
                  <SubscriptionCard type="singleBlog" />
                </Box>
              </Box>
            </Container>
          </>
        ) : (
          <Container>
            <SearchResult
              seacrhFilterRsult={seacrhFilterRsult}
              onBackBlog={onBackBlog}
              searchValue={searchValue}
              highlightedData={highlightedData}
            />
          </Container>
        )}
      </div>
    </>
  );
};

export default BlogArticle;
